import { useCallback } from 'react';

import { usePostHog } from 'posthog-js/react';

import { FeatureFlagNameFormatter } from '@/constants/FeatureFlagEvents';

const useFeatureFlags = () => {
  const posthog = usePostHog();

  const identifyUser = useCallback(
    (userId: string) => {
      posthog.identify(userId);
    },
    [posthog]
  );

  const captureEvent = useCallback(
    (eventName: string) => {
      posthog.capture(eventName);
    },
    [posthog]
  );

  const getFeatureFlag = useCallback(
    (featureFlag: string) => {
      return posthog.getFeatureFlag(featureFlag);
    },
    [posthog]
  );

  const getFeatureFlagPayload = useCallback(
    (featureFlag: string) => {
      return posthog.getFeatureFlagPayload(featureFlag);
    },
    [posthog]
  );

  const getDistinctId = useCallback(() => {
    return posthog.get_distinct_id();
  }, [posthog]);

  return {
    isPlaylistingDisabled: posthog.isFeatureEnabled('isPlaylistingDisabled'),
    isFanHubDisabled: posthog.isFeatureEnabled('isFanHubDisabled'),
    isMetaAdsDisabled: posthog.isFeatureEnabled('isMetaAdsDisabled'),
    isTikTokAdsDisabled: posthog.isFeatureEnabled('isTikTokAdsDisabled'),
    isPricingTestGroup: getFeatureFlag(FeatureFlagNameFormatter('isVariablePricingEnabled')) === 'test_group_lower',
    isHomeScreenBannerEnabled: posthog.isFeatureEnabled(FeatureFlagNameFormatter('homeScreenBanner')),
    isPreviousPlaylistsBannerEnabled: posthog.isFeatureEnabled(FeatureFlagNameFormatter('previousPlaylistsBanner')),

    identifyUser: identifyUser,
    captureEvent: captureEvent,
    getFeatureFlag: getFeatureFlag,
    getFeatureFlagPayload: getFeatureFlagPayload,
    getDistinctId: getDistinctId,
  };
};

export default useFeatureFlags;
